<template>
  <main class="page page-document" v-if="document">
    <BreadcrumbsComponent
      :title="document.title"
      :links="[{ title: 'Документы', to: { name: 'documents' } }]"
    />
    <div class="page__container">
      <div class="page__content">
        <aside class="document-aside">
          <div class="document-aside__item" v-if="document.document_type">
            <span class="document-aside__item-title">Тип:</span>
            <span class="document-aside__item-content">{{ document.document_type.title }}</span>
          </div>
          <div class="document-aside__item" v-if="document.accepted_at">
            <span class="document-aside__item-title">Документ от:</span>
            <span class="document-aside__item-content">
              {{ document.accepted_at | humanDateShort(false) }}
            </span>
          </div>
          <div class="document-aside__item" v-if="document.file">
            <a :href="document.file.path" class="btn btn--main" :download="document.file.origin_name">
              <IconComponent category="vuesax-bold" name="receive-square" />
              <span>
                Скачать
                <template v-if="document.file.type && document.file.size">
                  ({{ document.file.type }}, {{ document.file.size }})
                </template>
              </span>
            </a>
          </div>
          <div class="document-aside__item">
            <span class="document-aside__item-title">Количество просмотров:</span>
            <span class="document-aside__item-content">{{ count }}</span>
          </div>
          <div class="document-aside__item">
            <span class="document-aside__item-title">Поделиться:</span>
            <div class="document-aside__item-socials">
              <ShareNetwork
                :url="$store.state._env.MEDIA_ENDPOINT + document.file.path"
                network="telegram"
                :title="document.title"
                :description="document.file.origin_name"
              >
                <IconComponent category="default" name="tg" />
              </ShareNetwork>
              <ShareNetwork
                :url="$store.state._env.MEDIA_ENDPOINT + document.file.path"
                network="whatsapp"
                :title="document.title"
                :description="document.file.origin_name"
              >
                <IconComponent category="default" name="wp" />
              </ShareNetwork>
              <ShareNetwork
                :url="$store.state._env.MEDIA_ENDPOINT + document.file.path"
                network="vk"
                :title="document.title"
                :description="document.file.origin_name"
              >
                <IconComponent category="default" name="vk" />
              </ShareNetwork>
            </div>
          </div>
        </aside>
        <iframe
          :src="`https://docs.google.com/gview?url=${
            $store.state._env.MEDIA_ENDPOINT + document.file.path
          }&embedded=true`"
          class="page-document__iframe"
        />
      </div>
    </div>
  </main>
  <NotFound v-else />
</template>

<script>
import BreadcrumbsComponent from "components/navigation/BreadcrumbsComponent.vue";
import IconComponent from "components/IconComponent.vue";
import DOCUMENT_OPEN_PAGE from "gql/pages/DocumentOpenPage.graphql";
import NotFound from "@/views/PageNotFound.vue";
import ADD_VIEW from "gql/mutations/AddView.graphql";

export default {
  name: "DocumentsOpenPage",
  async asyncData({ res, apollo, store, route }) {
    if (!route.params.id && res) {
      res.status(404);
    }
    await apollo.defaultClient
      .query({
        query: DOCUMENT_OPEN_PAGE,
        variables: {
          id: parseInt(route.params.id),
          type_id: store.state._types.COUNTER_DOCUMENT_TYPE,
        },
      })
      .then(({ data }) => {
        if (data) {
          store.dispatch("documents/save", data);
        } else {
          if (res) {
            res.status(404);
          }
        }
      })
      .catch(() => {});
  },
  computed: {
    document() {
      return this.$store.state.documents.acts_item;
    },
    count() {
      return this.$store.state.documents.item_views_count;
    },
  },
  mounted() {
    this.counterUpdate();
  },
  methods: {
    counterUpdate() {
      const variables = {
        id: parseInt(this.$route.params.id),
        type_id: this.$store.state._types.COUNTER_DOCUMENT_TYPE,
      };
      this.$apollo
        .mutate({
          mutation: ADD_VIEW,
          variables: variables,
        })
        .then(({ data }) => {
          if (data && data.AddView) {
            console.log("успешно");
          }
        })
        .catch(({ graphQLErrors }) => {
          this.parseGqlErrors(graphQLErrors);
        });
    },
  },
  metaInfo() {
    const meta = this.$store.state.documents.acts_item;
    return {
      title: meta?.title,
    };
  },
  components: { NotFound, IconComponent, BreadcrumbsComponent },
};
</script>

<style lang="stylus">
@import "~@/styles/components/doc-aside.styl"
.page-document {
  display grid
  grid-gap 50px
  margin-bottom 50px
  +below(1024px) {
    grid-gap 30px
    margin-bottom 30px
  }

  & .page__content {
    grid-gap 30px
    align-items start
    grid-template-columns 328px 1fr
    +below(1024px) {
      grid-template-columns 1fr
    }
  }

  &__iframe {
    border none
    height 100vh
    width 100%
    border-radius 10px
    +below(1024px) {
      height 100vw
    }
  }
}
</style>
